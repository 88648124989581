import BigNumber from 'bignumber.js'
import React from 'react'
import styled from 'styled-components'
import { BIG_ZERO } from 'utils/bigNumber'
import { Flex, Text, Box } from '@plantswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { VerticalGardenCategory } from 'config/constants/types'
import { VerticalGarden } from 'state/types'
import ApprovalAction from './ApprovalAction'
import StakeActions from './StakeActions'
import HarvestActions from './HarvestActions'
import HarvestPlantActions from './HarvestPlantActions'

const InlineText = styled(Text)`
  display: inline;
`

interface CardActionsProps {
  verticalGarden: VerticalGarden
  stakedBalance: BigNumber
}

const CardActions: React.FC<CardActionsProps> = ({ verticalGarden, stakedBalance }) => {
  const { vgId, stakingToken, stakingRewardToken, verticalEarningToken, harvest, verticalGardenCategory, userData, stakingRewardTokenPrice } = verticalGarden
  // Vertical Gardens using native BNB behave differently than pools using a token
  const isBnbPool = verticalGardenCategory === VerticalGardenCategory.BINANCE
  const { t } = useTranslation()
  const allowance = userData?.allowance ? new BigNumber(userData.allowance) : BIG_ZERO
  const stakingTokenBalance = userData?.stakingTokenBalance ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO
  const earnings = userData?.pendingReward ? new BigNumber(userData.pendingReward) : BIG_ZERO
  const needsApproval = !allowance.gt(0) && !isBnbPool
  const isStaked = stakedBalance.gt(0)
  const isLoading = !userData

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column">
        {harvest && (
          <>
            <Box display="inline">
              <InlineText color="secondary" textTransform="uppercase" bold fontSize="12px">
                {`${stakingRewardToken.symbol} `}
              </InlineText>
              <InlineText color="textSubtle" textTransform="uppercase" bold fontSize="12px">
                {t('Earned')}
              </InlineText>
            </Box>
            <HarvestActions
              earnings={earnings}
              stakingRewardToken={stakingRewardToken}
              vgId={vgId}
              stakingRewardTokenPrice={stakingRewardTokenPrice}
              isBnbPool={isBnbPool}
              isLoading={isLoading}
            />
            <Box display="inline">
              <InlineText color="secondary" textTransform="uppercase" bold fontSize="12px">
                {`${verticalEarningToken.symbol} `}
              </InlineText>
              <InlineText color="textSubtle" textTransform="uppercase" bold fontSize="12px">
                {t('Earned')}
              </InlineText>
            </Box>
            <HarvestPlantActions
              earnings={earnings}
              stakingRewardToken={stakingRewardToken}
              vgId={vgId}
              stakingRewardTokenPrice={stakingRewardTokenPrice}
              isBnbPool={isBnbPool}
              isLoading={isLoading}
            />
          </>
        )}
        <Box display="inline">
          <InlineText color={isStaked ? 'secondary' : 'textSubtle'} textTransform="uppercase" bold fontSize="12px">
            {isStaked ? stakingToken.symbol : t('Stake')}{' '}
          </InlineText>
          <InlineText color={isStaked ? 'textSubtle' : 'secondary'} textTransform="uppercase" bold fontSize="12px">
            {isStaked ? t('Staked') : `${stakingToken.symbol}`}
          </InlineText>
        </Box>
        {needsApproval ? (
          <ApprovalAction verticalGarden={verticalGarden} isLoading={isLoading} />
        ) : (
          <StakeActions
            isLoading={isLoading}
            verticalGarden={verticalGarden}
            stakingTokenBalance={stakingTokenBalance}
            stakedBalance={stakedBalance}
            isBnbPool={isBnbPool}
            isStaked={isStaked}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default CardActions
