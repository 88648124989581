export enum CurrencyModalView {
  search,
  manage,
  importToken,
  importList,
}

export enum NftModalView {
  search,
  manage,
  importNft,
  importNftList,
}